import React from 'react';
import styles from '../style/style-card.module.scss';

export default function AddCompany() {
  return (
    <section className={styles.card_components_admin}>
      <div className={styles.card_components_admin__block}>
        <div className={styles.card_components_admin__title}>Сотрудники</div>
      </div>
    </section>
  );
}
