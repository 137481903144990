import React, { useState, useEffect } from 'react';
import styles from './Error.module.css';

export default function Error({ error }) {
  const [width1, setWidth1] = useState(12);
  const [height1, setHeight1] = useState(12);
  const [width2, setWidth2] = useState(47);
  const [height2, setHeight2] = useState(12);

  // Используем хук useEffect для обновления значения width и height при изменении размеров окна
  useEffect(() => {
    function handleResize() {
      setWidth1(window.innerWidth / 14); // делим ширину на два
      setHeight1(window.innerHeight / 14); // делим высоту на два
      setWidth2(window.innerWidth / 14); // делим ширину на четыре
      setHeight2(window.innerHeight / 14); // делим высоту на четыре
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={styles.error_container}>
      <div className={styles.error_block}>
        <svg width={width1} height={height1} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.87643 0.364345C10.3622 -0.121448 11.1498 -0.121448 11.6356 0.364345C12.1214 0.850138 12.1214 1.63776 11.6356 2.12356L7.75921 5.99999L11.6357 9.87644C12.1214 10.3622 12.1214 11.1499 11.6357 11.6357C11.1499 12.1214 10.3622 12.1214 9.87645 11.6357L6 7.7592L2.12355 11.6357C1.63776 12.1214 0.850137 12.1214 0.364345 11.6357C-0.121448 11.1499 -0.121448 10.3622 0.364345 9.87644L4.24079 5.99999L0.36436 2.12356C-0.121432 1.63776 -0.121432 0.850138 0.36436 0.364345C0.850152 -0.121448 1.63778 -0.121448 2.12357 0.364345L6 4.24078L9.87643 0.364345Z"
            fill="url(#paint0_linear_2265_10244)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2265_10244"
              x1="34.6487"
              y1="8.17679"
              x2="4.22469"
              y2="24.8833"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FCFDFF" />
              <stop offset="1" stopColor="#FF9988" />
            </linearGradient>
          </defs>
        </svg>

        <svg width={width2} height={height2} viewBox="0 0 47 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.62 11.78C5.32667 11.78 4.18 11.5333 3.18 11.04C2.19333 10.5333 1.41333 9.84 0.84 8.96C0.28 8.06667 0 7.04 0 5.88C0 4.70667 0.266667 3.68 0.8 2.8C1.33333 1.92 2.07333 1.23333 3.02 0.74C3.96667 0.246666 5.05333 0 6.28 0C7.49333 0 8.55333 0.266668 9.46 0.800001C10.3667 1.33333 11.0667 2.08667 11.56 3.06C12.0667 4.02 12.32 5.14 12.32 6.42H1.4V4.96H11.54L10.46 5.62C10.42 4.78 10.22 4.05333 9.86 3.44C9.5 2.82667 9.01333 2.35333 8.4 2.02C7.78667 1.68667 7.07333 1.52 6.26 1.52C5.38 1.52 4.61333 1.7 3.96 2.06C3.30667 2.40667 2.79333 2.9 2.42 3.54C2.06 4.18 1.88 4.93333 1.88 5.8C1.88 6.70667 2.08667 7.49333 2.5 8.16C2.91333 8.82667 3.49333 9.34 4.24 9.7C4.98667 10.06 5.86667 10.24 6.88 10.24C7.8 10.24 8.7 10.0867 9.58 9.78C10.4733 9.47333 11.2733 9.05333 11.98 8.52V9.96C11.2867 10.52 10.46 10.9667 9.5 11.3C8.54 11.62 7.58 11.78 6.62 11.78Z"
            fill="url(#paint0_linear_2265_10241)"
          />
          <path
            d="M14.0858 0.26H15.9258L16.5658 4.1V11.52H14.7258V3.76L14.0858 0.26ZM22.5458 0.220001V1.88C22.2924 1.8 21.9924 1.74 21.6458 1.7C21.3124 1.66 20.9924 1.64 20.6858 1.64C19.8858 1.64 19.1724 1.82 18.5458 2.18C17.9191 2.54 17.4324 3.06667 17.0858 3.76C16.7391 4.44 16.5658 5.24667 16.5658 6.18L16.0458 5.46C16.0724 4.67333 16.1991 3.94667 16.4258 3.28C16.6524 2.61333 16.9724 2.04 17.3858 1.56C17.8124 1.06667 18.3191 0.686666 18.9058 0.42C19.5058 0.14 20.1791 0 20.9258 0C21.2058 0 21.4924 0.0199992 21.7858 0.0599995C22.0924 0.099999 22.3458 0.153334 22.5458 0.220001Z"
            fill="url(#paint1_linear_2265_10241)"
          />
          <path
            d="M29.6878 11.78C28.3678 11.78 27.2011 11.5333 26.1878 11.04C25.1878 10.5467 24.4078 9.85333 23.8478 8.96C23.2878 8.06667 23.0078 7.04 23.0078 5.88C23.0078 4.70667 23.2878 3.68 23.8478 2.8C24.4078 1.92 25.1878 1.23333 26.1878 0.74C27.2011 0.246666 28.3678 0 29.6878 0C31.0211 0 32.1878 0.246666 33.1878 0.74C34.1878 1.23333 34.9678 1.92 35.5278 2.8C36.1011 3.68 36.3878 4.70667 36.3878 5.88C36.3878 7.04 36.1011 8.06667 35.5278 8.96C34.9678 9.85333 34.1878 10.5467 33.1878 11.04C32.1878 11.5333 31.0211 11.78 29.6878 11.78ZM29.6878 10.24C30.6745 10.24 31.5278 10.0667 32.2478 9.72C32.9678 9.36 33.5211 8.85333 33.9078 8.2C34.3078 7.54667 34.5078 6.77333 34.5078 5.88C34.5078 5 34.3078 4.23333 33.9078 3.58C33.5211 2.92667 32.9678 2.42 32.2478 2.06C31.5278 1.7 30.6745 1.52 29.6878 1.52C28.7145 1.52 27.8611 1.7 27.1278 2.06C26.4078 2.42 25.8545 2.92667 25.4678 3.58C25.0811 4.23333 24.8878 5 24.8878 5.88C24.8878 6.77333 25.0811 7.54667 25.4678 8.2C25.8545 8.85333 26.4078 9.36 27.1278 9.72C27.8611 10.0667 28.7145 10.24 29.6878 10.24Z"
            fill="url(#paint2_linear_2265_10241)"
          />
          <path
            d="M38.1678 0.26H40.0078L40.6478 4.1V11.52H38.8078V3.76L38.1678 0.26ZM46.6278 0.220001V1.88C46.3745 1.8 46.0745 1.74 45.7278 1.7C45.3945 1.66 45.0745 1.64 44.7678 1.64C43.9678 1.64 43.2545 1.82 42.6278 2.18C42.0011 2.54 41.5145 3.06667 41.1678 3.76C40.8211 4.44 40.6478 5.24667 40.6478 6.18L40.1278 5.46C40.1545 4.67333 40.2811 3.94667 40.5078 3.28C40.7345 2.61333 41.0545 2.04 41.4678 1.56C41.8945 1.06667 42.4011 0.686666 42.9878 0.42C43.5878 0.14 44.2611 0 45.0078 0C45.2878 0 45.5745 0.0199992 45.8678 0.0599995C46.1745 0.099999 46.4278 0.153334 46.6278 0.220001Z"
            fill="url(#paint3_linear_2265_10241)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2265_10241"
              x1="70"
              y1="-14.98"
              x2="21.0849"
              y2="11.8805"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FCFDFF" />
              <stop offset="1" stopColor="#FF9988" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_2265_10241"
              x1="70"
              y1="-14.98"
              x2="21.0849"
              y2="11.8805"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FCFDFF" />
              <stop offset="1" stopColor="#FF9988" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_2265_10241"
              x1="70"
              y1="-14.98"
              x2="21.0849"
              y2="11.8805"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FCFDFF" />
              <stop offset="1" stopColor="#FF9988" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_2265_10241"
              x1="70"
              y1="-14.98"
              x2="21.0849"
              y2="11.8805"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FCFDFF" />
              <stop offset="1" stopColor="#FF9988" />
            </linearGradient>
          </defs>
        </svg>
        <p className={styles.error_text}> Ошибка: {error}</p>
      </div>
    </div>
  );
}
