export const blockData = [
  {
    title: 'Архитектура и концепция',
    content: 'Создание и адаптация архитектурных концепций, разработка документации',
    path: 'viewConstruction=Архитектура и концепция',
    blocks: [
      {
        block_name: 'Архитектура',
        block_content: 'Разработка концепции, архитектурное проектирование - от идеи до ввода объекта в эксплуатацию',
      },
      {
        block_name: 'Адаптация зарубежных концепций',
        block_content: 'Подготовка проектной и рабочей документации на базе архитектурного/инженерного решения',
      },
      {
        block_name: 'Дизайн-проекты',
        block_content: 'Для жилых и общественных помещений. Авторский надзор, ЗD - моделирование, VR - визуализация',
      },
      {
        block_name: 'Проектная и рабочая документация',
        block_content: 'Прохождение экспертизы, авторский надзор, сопровождение строительства',
      },
    ],
  },
  {
    title: 'Градостроительство и генплан',
    content: 'Разработка планов территорий, учитывающих транспортную инфраструктуру, зонирование и устойчивое развитие',
    path: 'viewConstruction=Градостроительство и генплан',
    blocks: [
      {
        block_name: 'Разработка документации',
        block_content:
          'Определение общей стратегии развития территории, формирование облика, определение функциональных зон, транспортная инфраструктура и другие аспекты градостроительства. Подготовка комплекса документов для экспертизы',
      },
      {
        block_name: 'Градостроительный анализ',
        block_content:
          'Анализ потенциала территории: исследование градостроительных, зональных и территориальных ограничений. Архитектурно-функциональные концепции населённого пункта, района, социально-бытовая инфраструктура и т.д.',
      },
      {
        block_name: 'Проектирование',
        block_content:
          'Разработка решений по проектируемому рельефу в Civil 3D, расчёты объёма земляных работ, подбор покрытий, материалов',
      },
      { block_name: 'Ландшафтный дизайн', block_content: 'Подбор МАФ, расчёт озеленения, дендропроектирование' },
      {
        block_name: 'Благоустройство',
        block_content:
          'Разработка комфортной городской среды, включая площади, парки, скверы, а также системы освещения, водоснабжения и водоотведения',
      },
    ],
  },
  {
    title: 'Конструктив',
    content: 'Разработка строительных конструкций и технических решений',
    path: 'viewConstruction=Конструктив',
    blocks: [
      {
        block_name: 'Конструктивные решения',
        block_content: 'Выполнение необходимых расчетов для обоснования принятых решений',
      },
      {
        block_name: 'Проверочные расчеты',
        block_content: 'Аудит проектных решений выполненных другими проектными компаниями, разработка рекомендаций',
      },
      {
        block_name: 'Выполнение математического моделирования',
        block_content: 'Анализ ветровых воздействий с учетом окружающей застройки и оценка пешеходной комфортности',
      },
      {
        block_name: 'Концептуальный анализ',
        block_content:
          'Анализ конструктивных схем и вариантов с учётом поиска архитектурной формы на начальной стадии развития проекта, включая оценку геометрии центрального ядра жёсткости',
      },
    ],
  },
  {
    title: 'Инженерия',
    content: 'Разработка внутренних и наружных инженерных систем для зданий и сооружений',
    path: 'viewConstruction=Инженерия',
    blocks: [
      {
        block_name: 'Разработка внутренних сетей',
        block_content:
          'Водоснабжение, водоотведение, электроснабжение, электроосвещение, отопление, вентиляция, кондиционирование, сети связи и автоматизация',
      },
      {
        block_name: 'Инженерное проектирование',
        block_content:
          'Теплоснабжение, газоснабжение, бытовое водоотведение, комплексная схема инженерного обеспечения',
      },
    ],
  },
  {
    title: 'Геотехника',
    content: 'Изучение и учет геологических и геотехнических особенностей местоположения проекта',
    path: 'viewConstruction=Геотехника',
    blocks: [
      {
        block_name: 'Проектирование подземных сооружений',
        block_content:
          'Применение специальных способов строительства. Проекты освоения подземного пространства для уникальных особо сложных объектов строительства',
      },
      {
        block_name: 'Геотехнический анализ',
        block_content:
          'Анализ и разработка рекомендаций по оптимизации проектной и изыскательской документации. Структурно-нелинейный трёхмерный анализ сложных конструктивных узлов',
      },
      {
        block_name: 'Аэродинамические исследования',
        block_content:
          'Комплексные аэродинамические исследования по объекту (распределение ветровых и снеговых нагрузок, ветровая комфортность в пешеходных зонах, проверка на резонансные вихревые возбуждения и аэродинамическую неустойчивость конструкций)',
      },
      {
        block_name: 'Расчёты конструктивных решений',
        block_content:
          'Основные и альтернативные расчёты конструктивных решений на основные и особые сочетания нагрузок (учёт этапности возведения, расчёты на сейсмические воздействия, прогрессирующее обрушение, образование карстовых воронок и т.д.)',
      },
      {
        block_name: 'Геотехнический и технический мониторинг',
        block_content:
          'Разработка программы. Обеспечение качества выполняемых работ. Обеспечение взаимодействия участников строительного процесса. Обеспечение безопасности людей и объектов строительства',
      },
    ],
  },
  {
    title: 'Наука',
    content: 'Применение научных исследований и знаний для разработки решений в проектировании и строительстве',
    path: 'viewConstruction=Наука',
    blocks: [
      {
        block_name: 'Научно-техническое сопровождение',
        block_content:
          'Расчётное сопровождение проектирования и строительства любой сложности на всех этапах работ. Экспертиза проектных решений (анализ и разработка рекомендаций)',
      },
      {
        block_name: 'Комплексные аэродинамические исследования',
        block_content:
          'Распределение ветровых и снеговых нагрузок, ветровая комфортность в пешеходных зонах, проверка на резонансные вихревые возбуждения и аэродинамическую неустойчивость конструкций',
      },
      {
        block_name: 'Альтернативные расчёты КР',
        block_content:
          'Расчёты на основные и особые нагрузки: учёт этапности возведения, расчёты на сейсмические воздействия, прогрессирующее обрушение, образование карстовых воронок и т.д.',
      },
    ],
  },
  {
    title: 'Технологии',
    content: 'Выполнение широкого спектра работ в различных направлениях технологических решений',
    path: 'viewConstruction=Технологии',
    blocks: [
      {
        block_name: 'Широкий спектр направлений технологических решений',
        block_content:
          'Предприятия питания, парковки и автомойки, магазины и торговые центры, спортивные объекты, гостиницы, медицинские и образовательные учреждения, офисные помещения, мусороудаление и вертикальный транспорт, различные направления промышленной технологии',
      },
      {
        block_name: 'Разработка концепций',
        block_content:
          'Выполнение технологического функционального зонирования и компоновочных решений, формирование технологических потоков. Расстановка оборудования в моделях Revit',
      },
      {
        block_name: 'Проектная документация',
        block_content:
          'Подготовка документации и прохождение экспертизы на основании разработанной технологической функциональной концепции',
      },
    ],
  },
  {
    title: 'Цифровые технологии',
    content:
      'Применение современных цифровых инструментов и методов для оптимизации проектирования и управления проектами',
    path: 'viewConstruction=Цифровые технологии',
    blocks: [
      {
        block_name: 'Цифровизация процессов строительства',
        block_content:
          'Разработка программного обеспечения, инфраструктуры и методик в цифровой среде для инженерного проектирования, девелопмента и строительства',
      },
      {
        block_name: 'Разработка нормативной базы',
        block_content:
          'База знаний, содержащая в себе стандарты, регламенты и инструкции, которые делают процесс информационного моделирования прозрачным',
      },
      {
        block_name: 'Разработка программного обеспечения',
        block_content: 'Разработка решений для автоматизации и оптимизации процессов информационного моделирования',
      },
      {
        block_name: 'Внедрение, обучение, консалтинг и аудит',
        block_content: 'Делимся накопленным опытом и помогаем выпускать качественный цифровой продукт',
      },
      // { block_name: "Концептуальный анализ", block_content: "Конструктивных схем и вариантов с учетом поиска архитектурной формы на начальной стадии развития проекта, включая оценку геометрии центрального кара жесткости" },
      // { block_name: "Концептуальный анализ", block_content: "Конструктивных схем и вариантов с учетом поиска архитектурной формы на начальной стадии развития проекта, включая оценку геометрии центрального кара жесткости" },
    ],
  },
];
