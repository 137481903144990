const bucket_url = process.env.REACT_APP_S3_BUCKET_URL;

const popupsData = [
  // {
  //   id: 1,
  //   title: "Marks Group",
  //   coordinates: [55.783, 37.5804],
  //   image: "../assets/projectFoto/office.jpg",
  //   buttonText: "Смотреть контакты",
  // },
  {
    id: 2,
    title: 'ГОСНИИ ГА',
    coordinates: [55.977355, 37.388843],
    image: `${bucket_url}/images/projectFoto/gosnii.png`,
    buttonText: '',
  },
  {
    id: 3,
    title: 'ЖК «Бадаевский»',
    coordinates: [55.748888, 37.557268],
    image: `${bucket_url}/images/projectFoto/badaevsky.png`,
    buttonText: '',
  },
  {
    id: 4,
    title: 'Офисно-деловое здание со встроенной подземной автостоянкой А23',
    coordinates: [55.787644, 37.332501],
    image: `${bucket_url}/images/projectFoto/a23.png`,
    buttonText: '',
  },
  {
    id: 5,
    title: 'Офисно-деловое здание со встроенной подземной автостоянкой А24',
    coordinates: [55.787644, 37.3329],
    image: `${bucket_url}/images/projectFoto/a24.png`,
    buttonText: '',
  },
  {
    id: 6,
    title: 'Офисно-деловое здание со встроенной подземной автостоянкой А27',
    coordinates: [55.787644, 37.3322],
    image: '',
    buttonText: '',
  },
  {
    id: 7,
    title: 'Офисно-деловое здание ТО4',
    coordinates: [55.7879, 37.3328],
    image: `${bucket_url}/images/projectFoto/to4.png`,
    buttonText: '',
  },
  {
    id: 8,
    title: 'ЖК Eniteo',
    coordinates: [55.677666, 37.586904],
    image: `${bucket_url}/images/projectFoto/eniteo.png`,
    buttonText: '',
  },
  {
    id: 9,
    title: 'B11',
    coordinates: [55.7879, 37.3331],
    image: `${bucket_url}/images/projectFoto/b11.png`,
    buttonText: '',
  },
  {
    id: 10,
    title: 'B12',
    coordinates: [55.7879, 37.3325],
    image: `${bucket_url}/images/projectFoto/b12.png`,
    buttonText: '',
  },
  {
    id: 11,
    title: 'ЖК «Вишнёвый сад»',
    coordinates: [55.722355, 37.532196],
    image: `${bucket_url}/images/projectFoto/vishevyi_sad.png`,
    buttonText: '',
  },
  {
    id: 12,
    title: 'Саввинская',
    coordinates: [55.734572, 37.565767],
    image: `${bucket_url}/images/projectFoto/savinskay.png`,
    buttonText: '',
  },
  {
    id: 13,
    title: 'МФК «Чкалов»',
    coordinates: [55.755859, 37.659461],
    image: `${bucket_url}/images/projectFoto/chkalov.png`,
    buttonText: '',
  },
  {
    id: 14,
    title: 'Гостиничный комплекс с апартаментами «Космо»',
    coordinates: [55.7456, 37.638782],
    image: `${bucket_url}/images/projectFoto/cosmo.png`,
    buttonText: '',
  },
  {
    id: 15,
    title: 'ТЦ «Хорошо»',
    coordinates: [55.777105, 37.523716],
    image: `${bucket_url}/images/projectFoto/horosho.png`,
    buttonText: '',
  },
  {
    id: 16,
    title: 'МФК SLAVA',
    coordinates: [55.780335, 37.580939],
    image: `${bucket_url}/images/projectFoto/slava.png`,
    buttonText: '',
  },
  {
    id: 17,
    title: 'ЖК «Родной город»',
    coordinates: [55.664091, 37.546264],
    image: `${bucket_url}/images/projectFoto/rodnoy_gorod.png`,
    buttonText: '',
  },
  {
    id: 18,
    title: 'Государственный Академический Большой Театр России',
    coordinates: [55.760221, 37.618561],
    image: `${bucket_url}/images/projectFoto/big_theater.png`,
    buttonText: '',
  },
  {
    id: 19,
    title: 'Искусственное сооружение',
    coordinates: [54.7779, 38.138505],
    image: '',
    buttonText: '',
  },
  {
    id: 20,
    title: 'МФК с апартаментами ЖК «Нагатинская пойма»',
    coordinates: [55.692756, 37.682251],
    image: `${bucket_url}/images/projectFoto/nagatinskay_poima.png`,
    buttonText: '',
  },
  {
    id: 21,
    title: 'МФК с подземной автостоянкой Level Нагатинская',
    coordinates: [55.682432, 37.637749],
    image: `${bucket_url}/images/projectFoto/level_nagatinskay.png`,
    buttonText: '',
  },
  {
    id: 22,
    title: 'ЖК PRIME PARK',
    coordinates: [55.792108, 37.540757],
    image: `${bucket_url}/images/projectFoto/prime_park.png`,
    buttonText: '',
  },
  {
    id: 23,
    title: 'ЖК «Большая Семёрка»',
    coordinates: [55.81037, 37.702544],
    image: `${bucket_url}/images/projectFoto/big_7.png`,
    buttonText: '',
  },
  {
    id: 24,
    title: 'ТПУ «Тимирязевская»',
    coordinates: [55.819245, 37.576317],
    image: `${bucket_url}/images/projectFoto/tpu_timiryazevskay.png`,
    buttonText: '',
  },
  {
    id: 25,
    title: 'ЖК LE DOME',
    coordinates: [55.742869, 37.605221],
    image: `${bucket_url}/images/projectFoto/le_dome.png`,
    buttonText: '',
  },
];

export default popupsData;
