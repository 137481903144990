export const selectBlocks = [
  {
    name: 'direction',
    label: 'Направление',
    options: [
      { value: 'Другое направление', label: 'Другое направление' },
      { value: 'Архитектура', label: 'Архитектура' },
      { value: 'Конструктив', label: 'Конструктив' },
      { value: 'Инженерия', label: 'Инженерия' },
      { value: 'Научно-техническое сопровождение', label: 'Научно-техническое сопровождение' },
      { value: 'BIM', label: 'BIM' },
      { value: 'Аналитика', label: 'Аналитика' },
      { value: 'Финансы', label: 'Финансы' },
      { value: 'Дизайн', label: 'Дизайн' },
      { value: 'IT', label: 'IT' },
      { value: 'Администрирование', label: 'Администрирование' },
      { value: 'Бэк офис', label: 'Бэк офис' },
      { value: 'Стажировки', label: 'Стажировки' },
      { value: 'Управление', label: 'Управление' },
    ],
  },
  {
    name: 'experience',
    label: 'Опыт работы',
    options: [
      { value: 'Без опыта', label: 'Без опыта' },
      { value: '1-3 года', label: '1-3 года' },
      { value: '3-5 лет', label: '3-5 лет' },
      { value: '5-10 лет', label: '5-10 лет' },
    ],
  },
  {
    name: 'location',
    label: 'Локация',
    options: [
      { value: 'Москва', label: 'Москва' },
      { value: 'Санкт-Петербург', label: 'Санкт-Петербург' },
      { value: 'Воронеж', label: 'Воронеж' },
      { value: 'Оренбург', label: 'Оренбург' },
      { value: 'Челябинск', label: 'Челябинск' },
      { value: 'Ташкент', label: 'Ташкент' },
    ],
  },
  {
    name: 'busyness',
    label: 'Занятость',
    options: [
      { value: 'Полная', label: 'Полная' },
      { value: 'Частичная', label: 'Частичная' },
      { value: 'Проектная', label: 'Проектная' },
    ],
  },
  {
    name: 'workMode',
    label: 'Режим работы',
    options: [
      { value: 'Офис', label: 'Офис' },
      { value: 'Гибрид', label: 'Гибрид' },
      { value: 'Удаленно', label: 'Удаленно' },
    ],
  },
  {
    name: 'skills',
    label: 'Ключевые навыки',
    options: [
      { value: 'Autodesk Revit', label: 'Autodesk Revit' },
      { value: 'Navisworks', label: 'Navisworks' },
      { value: 'BIM360', label: 'BIM360' },
      { value: 'ArchiCAD', label: 'ArchiCAD' },
      { value: 'AutoCAD', label: 'AutoCAD' },
      { value: '3ds Max', label: '3ds Max' },
      { value: 'civil 3d', label: 'civil 3d' },
      { value: 'SCAD', label: 'SCAD' },
      { value: 'Лира САПР', label: 'Лира САПР' },
      { value: 'Lira soft', label: 'Lira soft' },
      { value: 'SOFiSTiK', label: 'SOFiSTiK' },
      { value: 'САПФИР', label: 'САПФИР' },
      { value: 'PLAXIS', label: 'PLAXIS' },
      { value: 'Autodesk СПДС Модуль', label: 'Autodesk СПДС Модуль' },
      { value: 'tekla', label: 'tekla' },
      { value: 'ansys', label: 'ansys' },
      { value: 'Midas', label: 'Midas' },
      { value: 'Rhinoceros 3D', label: 'Rhinoceros 3D' },
      { value: 'Grasshopper', label: 'Grasshopper' },
      { value: 'Unreal Engine', label: 'Unreal Engine' },
      { value: 'SketchUp', label: 'SketchUp' },
      { value: 'SQL', label: 'SQL' },
      { value: 'C#', label: 'C#' },
      { value: 'Python', label: 'Python' },
      { value: '1C', label: '1C' },
      { value: 'MS Project', label: 'MS Project' },
      { value: 'MS Office', label: 'MS Office' },
      { value: 'MS PowerPoint', label: 'MS PowerPoint' },
      { value: 'Photoshop', label: 'Photoshop' },
      { value: 'Figma', label: 'Figma' },
      { value: 'Bitrix', label: 'Bitrix' },
      { value: 'Miro', label: 'Miro' },
    ],
  },
  {
    name: 'benefits',
    label: 'Плюсы работы',
    options: [
      { value: 'Менторство и наставничество', label: 'Менторство и наставничество' },
      { value: 'Фруктовый понедельник', label: 'Фруктовый понедельник' },
      { value: 'Корпоративный английский', label: 'Корпоративный английский' },
      { value: 'НС и ДМС со стоматологией', label: 'НС и ДМС со стоматологией' },
      { value: 'Занятие в фитнес клубе', label: 'Занятие в фитнес клубе' },
      { value: 'Командные виды спорта, йога', label: 'Командные виды спорта, йога' },
      { value: 'Библиотека профессиональной литературы', label: 'Библиотека профессиональной литературы' },
      { value: 'Обучение Revit', label: 'Обучение Revit' },
      { value: 'Корпоративное такси для встреч', label: 'Корпоративное такси для встреч' },
      { value: 'Зажигательные корпоративы', label: 'Зажигательные корпоративы' },
      {
        value: 'Адаптация и досуг от совета молодых специалистов',
        label: 'Адаптация и досуг от совета молодых специалистов',
      },
      { value: 'Обучение и карьерный рост', label: 'Обучение и карьерный рост' },
      { value: 'Удаленная работа', label: 'Удаленная работа' },
      { value: 'Гибкий график работы', label: 'Гибкий график работы' },
      { value: 'Новогодние подарки детям', label: 'Новогодние подарки детям' },
    ],
  },
];

export const buttonsFilter = ['Вакансии', 'Стажировки', 'Главная hr'];
